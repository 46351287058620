/// Estilos de Boton Guardar

.btn-save {
  background-color: #006fb9 !important;
}

.btn-save:hover {
  background-color: #00538a !important;
  border-color: #00538a !important;
}

.btn-save:active {
  background-color: #003c64 !important;
}

.btn-save:disabled {
  background-color: #6599bb !important;
  border-color: #6599bb !important;
}

/// Estilos de Boton Cancelar

.btn-cancelar {
  background-color: #e4e4e4 !important;
  color: #086fb9 !important;
}

.btn-cancelar:hover {
  background-color: #d4d4d4 !important;
  border-color: #d4d4d4 !important;
}

.btn-cancelar:active {
  background-color: #cecdcd !important;
}

.btn-cancelar:disabled {
  background-color: #f3f3f3 !important;
  border-color: #f3f3f3 !important;
}

////Estilos de Boton Filtrar

.btn-filter {
  background-color: #006cff !important;
  border-color: #006cff;
  color: #ffffff;
}

.btn-filter:focus {
  background-color: #0960d6 !important;
  border-color: #0960d6 !important;
  color: #ffffff;
}

.btn-filter:hover {
  background-color: #0960d6 !important;
  border-color: #0960d6 !important;
  color: #ffffff;
}

.btn-filter:active {
  background-color: #0956be !important;
}

.btn-filter:disabled {
  background-color: #78b0fe !important;
  border-color: #78b0fe !important;
}

////Estilos de Boton Upload

.btn-upload {
  background-color: #006cff !important;
  border-color: #0960d6;
  color: #ffffff;
}

.btn-upload:hover {
  background-color: #00538a !important;
  border-color: #00538a !important;
  color: #ffffff;
}

.btn-upload:active {
  background-color: #003c64 !important;
}

.btn-upload:disabled {
  background-color: #6599bb !important;
  border-color: #6599bb !important;
}

////Estilos de Boton Donwload

.btn-donwload {
  background-color: #086fb9 !important;
  border-color: #086fb9;
  color: #ffffff;
}

.btn-donwload:hover {
  background-color: #00538a !important;
  border-color: #00538a !important;
  color: #ffffff;
}

.btn-donwload:active {
  background-color: #003c64 !important;
}

.btn-donwload:disabled {
  background-color: #6599bb !important;
  border-color: #6599bb !important;
}

////Estilos de Boton Agregar

.btn-add {
  background-color: #66ba1d !important;
  border-color: #66ba1d;
  color: #ffffff;
}

.btn-add:hover {
  background-color: #569b1a !important;
  border-color: #569b1a !important;
  color: #ffffff;
}

.btn-add:active {
  background-color: #447e12 !important;
}

.btn-add:disabled {
  background-color: #9fd273 !important;
  border-color: #9fd273 !important;
}
