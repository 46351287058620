// Here you can add other styles
thead > tr > th {
  span.sort-icon {
    cursor: pointer;
  }
}

.table .thead-light th {
  background-color: #f8f9fa;
}

.nav-dropdown-items.children {
  border-radius: 0 0 15px 15px;

  .nav-item {
    .nav-dropdown-items.children {
      .nav-item > a {
        padding-left: 45px;
      }
    }

    & > a {
      padding-left: 30px;
    }
  }
}

.nav-link-success,
.nav-link-danger,
.nav-link-info,
.nav-link-warning {
  color: $white !important;
}

.ui-growl {
  z-index: 9999 !important;
}

.open > .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.dropdown-menu {
  display: block;
  visibility: hidden;
  -webkit-transition: opacity 500ms ease, visibility 500ms ease;
  -moz-transition: opacity 500ms ease, visibility 500ms ease;
  -o-transition: opacity 500ms ease, visibility 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease;
}

//CURSOR-ALLOW
button:disabled {
  cursor: not-allowed;
}

.bread-menu {
  top: 101px !important;
  padding: 0.6rem 1rem;
}

.breadcrumb {
  border-radius: 0px;
}

.ui-widget a {
  font-size: 1em !important;
}

.card-header .card-actions a,
.card-header .card-actions button {
  min-width: 50px;
  padding: 0.75rem;
}

accordion-group > .card {
  margin-bottom: 0.5rem;
}

accordion-group .card-header {
  cursor: pointer;
}

.sortable-item {
  padding: 6px 12px;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.4em;
  text-align: center;
  cursor: grab;
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: #adadad;
}

.sortable-item-active {
  background-color: #e6e6e6;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.sortable-wrapper {
  min-height: 150px;
}

body .ui-paginator .ui-dropdown {
  height: inherit;
}

.sidebar-fixed .sidebar {
  border-right: 1px solid $gray-300;
}

//BTN-SECONDARY-CUSTOM
$blue-secondary-border: #bfe3fc;

.btn-secondary {
  color: $blue !important;
}

.btn-outline-secondary {
  color: $blue;
  border-color: $blue-secondary-border;
}

.btn-outline-secondary:hover {
  color: $blue;
  background-color: $blue-secondary;
  border-color: $blue-secondary-border;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: $blue;
  background-color: $blue-secondary;
  border-color: $blue-secondary-border;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: $blue;
  background-color: transparent;
}
