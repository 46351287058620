@import '../scss/_bootstrap-variables.scss';

.header-accordion-primary {
  color: var(--azul);
  font-weight: bold;
  font-family: Arial, sans-serif;
}

.header-accordion-primary i {
  font-weight: bold;
}

.p-accordion-toggle-icon {
  display: none;
}
