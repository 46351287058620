// scss-lint:disable QualifyingElement
hr.transparent {
  border-top: 1px solid transparent;
}

.popover{
  border: none;
  border-radius: 15px;
  box-shadow: 0px 23px 44px rgba(176,183,195,0.14);
  .popover-title{
    border-radius: 15px 15px 0 0;
  }
}

.alert{
  border-radius: 15px;
}