.p-datatable .p-sortable-column.p-highlight {
  color: $blue;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: $blue;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: $blue !important;
  border-color: $blue !important;
}

.p-dialog .p-dialog-header {
  background: $blue !important;
  color: #fefeff !important;
}

input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file']::-webkit-file-upload-button,
button {
  border-radius: 3px;
  border-width: 1px;
}

// Personalizacion file upload component
.p-fileupload .p-fileupload-buttonbar {
  padding: 0% !important;
  border: none !important;
  background-color: transparent !important;
}

.p-fileupload .p-fileupload-content {
  padding: 0% !important;
  border: none !important;
  background-color: transparent !important;
}

.p-fileupload-content .p-progressbar {
  display: none;
}

.p-fileupload-content .p-fileupload-row > div:first-child,
.p-fileupload-content .p-fileupload-row > div:nth-child(3) {
  display: none;
}

p-calendar,
p-dropdown {
  border: 0 !important;
}

.p-datepicker:not(.p-datepicker-inline) {
  z-index: 1030 !important;
}

