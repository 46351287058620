// Bootstrap overrides
//
// Color system
//

$white: #fff;
$gray-50: #fafafa;
$gray-100: #f8f9fa;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$black: #292930 !default;

$blue: #006fb9 !default;
$blue-accent: #006cff !default;
$azul: #006fb8 !default;
$dark-blue: #014070 !default;
$blue-info: #00a9e0 !default;
$blue-secondary: #f1f4fa !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d60d2b !default;
$orange: #fe5000 !default;
$yellow: #ffdd35 !default;
$green: #50b826 !default;
$teal: #20c997 !default;
$cyan: #63c2de;
$amarillo: #ffdc2f !default;
$rosa: #e83e8c !default;
$verde: #0d8e16 !default;

$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $blue-info,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
  'blue-dark': $dark-blue,
  'rosa': $rosa,
  'amarillo': $amarillo,
  'verde': $verde,
);

$theme-colors: (
  'primary': $blue,
  'secondary': $blue-secondary,
  'success': $green,
  'info': $blue-info,
  'warning': $orange,
  'danger': $red,
  'light': $gray-200,
  'dark': $dark-blue,
  'verde': $verde,
  'rosa': $rosa,
  'amarillo': $amarillo,
  'indigo': $indigo,
  'accent': $blue-accent,
  'pink': $pink,
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: true;
$enable-flex: true;
// Body
//
// Settings for the `<body>` element.

$body-bg: $gray-100;
$body-color: $gray-900 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 1rem;

// Breadcrumbs

$breadcrumb-bg: #fff;
$breadcrumb-margin-bottom: 1.5rem;

// Cards

$card-border-color: $gray-300;
$card-cap-bg: $gray-100;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-300;
$dropdown-divider-bg: $gray-100;

// Buttons

$btn-secondary-border: $gray-300;

// Progress bars

$progress-bg: $gray-100;

// Tables

$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;

// Forms

$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;

$zindex-dropdown: 1025 !default;
$zindex-sticky: 1009 !default;

$custom-file-text: ();
