.bg-primary,
.bg-success,
.bg-danger,
.bg-dark,
.bg-cyan,
.bg-info,
.bg-inverse {
  color: #fff;
}

.bg-blue {
  background-color: $blue;
  color: #fff;
}

.bg-indigo {
  background-color: $indigo;
  color: #fff;
}

.bg-purple {
  background-color: $purple;
  color: #fff;
}

.bg-pink {
  background-color: $pink;
  color: #fff;
}

.bg-red {
  background-color: $red;
  color: #fff;
}

.bg-orange {
  background-color: $orange;
  color: #fff;
}

.bg-yellow {
  background-color: $yellow;
  color: #000;
}

.bg-warning {
  background-color: $yellow;
  color: #fff;
}

.bg-green {
  background-color: $green;
  color: #fff;
}

.bg-teal {
  background-color: $teal;
  color: #fff;
}

.bg-cyan {
  background-color: $cyan;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.bg-gray-200 {
  background-color: $gray-200;
}

.bg-gray-300 {
  background-color: $gray-300;
}

.bg-gray-400 {
  background-color: $gray-400;
}

.bg-gray-500 {
  background-color: $gray-500;
  color: #fff;
}

.bg-gray-600 {
  background-color: $gray-600;
  color: #fff;
}

.bg-gray-700 {
  background-color: $gray-700;
  color: #fff;
}

.bg-gray-800 {
  background-color: $gray-800;
  color: #fff;
}

.bg-gray-900 {
  background-color: $gray-900;
  color: #fff;
}
